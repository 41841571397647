import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'

import Seo from '../components/general/seo'
import Layout from '../components/layout/layout'
import styles from './about.module.scss'
import SectionTitle from '@/components/top/section-title'

import ViewingEnv from '@/components/video/viewing-env'
import { BreadcrumbContext } from '@/types/breadcrumb'

const About: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="Cognition Cafeってなに？" />
      <div className={styles.wrap}>
        <h1>
          <SectionTitle theme="dark">Cognition Cafeってなに？</SectionTitle>
        </h1>
        <div className={styles.aboutBody}>
          <h2>認知機能（cognition）という言葉をご存知ですか？</h2>
          <p>
            「cognition
            cafe」は、医療従事者のみなさんに認知機能（cognition）を「知り」「学び」「考える」きっかけをお届けするオンラインカフェスペースです。
            <br />
            「認知機能」とは脳の各機能を制御して、日常生活をよりよく過ごすためには欠かすことができないものです。
            <br />
            医療の観点では、精神疾患の治療ターゲットとして、薬物治療ばかりでなくリハビリテーション領域でも注目されており、医療従事者が正しく理解し、質の高い医療の提供が望まれます。
            <br />
            精神科医師・リハビリ医師・看護師・心理士・ケアスタッフなど認知機能改善に携わる多くの方々に、認知機能を知っていただき、当事者の日常生活を改善するために必要な専門職の役割や知見を学ぶことを、かしこまったセミナーではないリラックスできるカフェのようなオンライン空間で体験していただきたいと考えています。
            <br />
            近年、テクノロジーの発達によって様々なデジタルツールが生まれていますが、「人と人との繋がり」が重要なことには変わりはありません。cafeとは「人と人が集まる場所」です。
            <br />
            本サイトでは認知機能の理解を通して「人と関わる」「人が集まる」「社会復帰ができる」そのようなことが実現することを願い、「cognition
            cafe」を立ち上げました。
            <br />
            「cognition
            cafe」では、認知機能に関する様々なコンテンツや企画を発信していきます。
            <br />
            カフェの雰囲気で、試写会やストアイベント、お客さま同士の交流を実現するなど、ぜひ一緒に「cognition
            cafe」を盛り上げてください。
            <br />
          </p>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'Cognition Cafeってなに？'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default About
